/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from "../../../../app/modules/auth";

export function AsideMenuMain() {
    const {currentUser} = useAuth() ?? {};
    let permissions: string = currentUser?.permissions?.toString() ?? '';
    if (permissions == '') {
        permissions = "{}";
    }
    const permissionsDecoded = JSON.parse(permissions);

    const intl = useIntl()
    return (
        <>
            {permissionsDecoded?.menu?.dashboard?.visibility && <AsideMenuItem
                to='/dashboard'
                title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                fontIcon='bi-bar-chart-line'
                bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
                className='py-3'
            />
            }
            {/*      <AsideMenuItem
        to='/builder'
        title='Layout Builder2'
        bsTitle='Layout Builder2'
        fontIcon='bi-gear'
        className='py-3'
      />
      <AsideMenuItemWithSubMain
        to='/crafted/pages'
        title='Crafted'
        fontIcon='bi-file-text'
        bsTitle='Crafted'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/profile/overview'
            title='Overview'
            bsTitle='Overview'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/projects'
            title='Projects'
            bsTitle='Projects'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            bsTitle='Campaigns'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            bsTitle='Documents'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
            bsTitle='Connections'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
            bsTitle='Horizontal'
          />
          <AsideMenuItem
            to='/crafted/pages/wizards/vertical'
            title='Vertical'
            bsTitle='Vertical'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/accounts' title='Accounts' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/account/overview'
            title='Overview'
            hasBullet={true}
            bsTitle='Overview'
          />
          <AsideMenuItem
            to='/crafted/account/settings'
            title='Settings'
            hasBullet={true}
            bsTitle='Settings'
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/widgets' title='Widgets' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/widgets/lists'
            title='Lists'
            bsTitle='Lists'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/statistics'
            title='Statistics'
            bsTitle='Statistics'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/charts'
            title='Charts'
            bsTitle='Charts'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/mixed'
            title='Mixed'
            bsTitle='Mixed'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/tables'
            title='Tables'
            bsTitle='Tables'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/crafted/widgets/feeds'
            title='Feeds'
            bsTitle='Feeds'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/apps/chat' title='Chat' hasBullet={true}>
          <AsideMenuItem
            to='/apps/chat/private-chat'
            title='Private Chat'
            bsTitle='Private Chat'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/group-chat'
            title='Group Chart'
            bsTitle='Group Chart'
            hasBullet={true}
          />
          <AsideMenuItem
            to='/apps/chat/drawer-chat'
            title='Drawer Chart'
            bsTitle='Drawer Chart'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSubMain>
 */}
            {permissionsDecoded?.menu?.finances?.visibility &&
                <AsideMenuItem
                    to='/finance-management/finances'
                    title='Finances'
                    fontIcon='bi-cash-coin'
                    bsTitle='Finances'
                    className='py-3'
                />
            }

            <AsideMenuItemWithSubMain to='/' title='Masters' fontIcon='bi-people' bsTitle='Masters'>

                {permissionsDecoded?.menu?.customers?.visibility &&
                    <AsideMenuItem
                        to='/user-management/users'
                        title='Customers management'
                    />
                }
                {permissionsDecoded?.menu?.financers?.visibility &&
                    <AsideMenuItem
                        to='/financer-management/users'
                        title='Financers management'
                    />
                }
                {permissionsDecoded?.menu?.employees?.visibility &&
                    <AsideMenuItem
                        to='/employee-management/users'
                        title='Employees management'
                    />
                }
                {permissionsDecoded?.menu?.distributors?.visibility &&
                    <AsideMenuItem
                        to='/distributor-management/users'
                        title='Distributors management'
                    />
                }

                {permissionsDecoded?.menu?.products?.visibility &&
                    <AsideMenuItem
                        to='/product-management/products'
                        title='Products Management'
                    />
                }
            </AsideMenuItemWithSubMain>

            <AsideMenuItemWithSubMain to='/' title='Expenses' fontIcon='bi-cash-stack' bsTitle='Expenses'>
                {permissionsDecoded?.menu?.expenses?.visibility &&
                    <AsideMenuItem
                        to='/expense-management/expenses'
                        title='Expenses'
                    />
                }

                {permissionsDecoded?.menu?.charges?.visibility &&
                    <AsideMenuItem
                        to='charges-management/charges'
                        title='Charges'
                    />
                }
            </AsideMenuItemWithSubMain>

            <AsideMenuItemWithSubMain to='/' title='Wallet' fontIcon='bi-wallet' bsTitle='Wallet'>
                {permissionsDecoded?.menu?.withdrawalrequests?.visibility &&
                    <AsideMenuItem
                        to='/reports/withdrawl-requests'
                        title='Withdrawl Requests'
                    />
                }
                {permissionsDecoded?.menu?.topuprequests?.visibility &&
                    <AsideMenuItem
                        to='/reports/topup-requests'
                        title='Topup Requests'
                    />
                }
            </AsideMenuItemWithSubMain>

            {permissionsDecoded?.menu?.reports?.visibility &&
                <AsideMenuItemWithSubMain to='/' title='Reports' fontIcon='bi-card-text' bsTitle='Reports'>
                    <AsideMenuItem to='/reports/report-1' title='Recovery Report' hasBullet={true}/>
                    <AsideMenuItem to='/reports/report-2' title='All Finance Report' hasBullet={true}/>
                    <AsideMenuItem to='/reports/print-card' title='Print Card' hasBullet={true}/>
                    <AsideMenuItem to='/reports/finance-report' title='Finance Report' hasBullet={true}/>
                    <AsideMenuItem to='/reports/print-emistatus' title='Print EMIs Status' hasBullet={true}/>
                    <AsideMenuItem to='/reports/print-noc' title='Print NOC' hasBullet={true}/>
                    <AsideMenuItem to='/finance-print-application' title='Print Application Form' hasBullet={true}/>
                    <AsideMenuItem to='/reports/acc-by-finance' title='Account Statement' hasBullet={true}/>
                    <AsideMenuItem to='/reports/monthly-closure' title='Monthy Closure' hasBullet={true}/>
                </AsideMenuItemWithSubMain>
            }
            {permissionsDecoded?.menu?.accesscontrol?.visibility &&
                <AsideMenuItem
                    to='reports/access-control'
                    title='Access Control'
                    fontIcon='bi-person-bounding-box'
                    bsTitle='Access Control'
                    className='py-3'
                />
            }
            {permissionsDecoded?.menu?.sitesettings?.visibility &&
                <AsideMenuItem
                    to='reports/site-settings'
                    title='Site Settings'
                    fontIcon='bi-gear'
                    bsTitle='Site Settings'
                    className='py-3'
                />
            }
            {/*<AsideMenuItem*/}
            {/*    to='reports/sponsor-control'*/}
            {/*    title='Sponsor Control'*/}
            {/*    fontIcon='bi-file-text'*/}
            {/*    bsTitle='Access Control'*/}
            {/*    className='py-3'*/}
            {/*  />*/}
        </>
    )
}

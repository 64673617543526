import {FC} from 'react'
import clsx from 'clsx'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {
    HeaderNotificationsMenu,
    HeaderUserMenu,
    QuickLinks,
    Search,
    ThemeModeSwitcher,
} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'
import {Link} from 'react-router-dom'

const itemClass = 'ms-1 ms-lg-3',
    btnClass = 'btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px',
    userAvatarClass = 'symbol-30px symbol-md-40px',
    btnIconClass = 'svg-icon-1'

const Topbar: FC = () => {
    const {config} = useLayout()
    const {currentUser} = useAuth()
    const walletBalance = currentUser?.wallet_balance?.running_balance ? currentUser?.wallet_balance?.running_balance : 0;
    return (
        <div className='d-flex align-items-stretch justify-self-end flex-shrink-0'>
            {/* begin::User */}
            <Link to='/wallet-transactions/index'>
                <div className={'mt-md-9 fw-bold text-dark fs-16'}><i className={'bi bi-wallet'}
                                                                      style={{fontSize: '1.3rem'}}></i> ₹ {walletBalance.toLocaleString('en-IN')}
                </div>
            </Link>

            <div className={clsx('d-flex align-items-center', itemClass)} id='kt_header_user_menu_toggle'>
                {/* begin::Toggle */}
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger='click'
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                    <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='metronic'/>
                </div>
                <HeaderUserMenu/>
                {/* end::Toggle */}
            </div>
            {/* end::User */}

            {/* begin::Aside Toggler */}
            {config.header.left === 'menu' && (
                <div className='d-flex align-items-center d-lg-none ms-2 me-n3' title='Show header menu'>
                    <div
                        className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
                        id='kt_header_menu_mobile_toggle'
                    >
                        <KTSVG path='/media/icons/duotune/text/txt001.svg' className='svg-icon-1'/>
                    </div>
                </div>
            )}
        </div>
    )
}

export {Topbar}

import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
    const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
    const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
    const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
    const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
    const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const FinancersPage = lazy(() => import('../modules/apps/financer-management/UsersPage'))
    const EmployeesPage = lazy(() => import('../modules/apps/employee-management/UsersPage'))
    const DistributorsPage = lazy(() => import('../modules/apps/distributor-management/UsersPage'))
    const ProductsPage = lazy(() => import('../modules/apps/product-management/UsersPage'))
    const FinancesPage = lazy(() => import('../modules/apps/finance-management/UsersPage'))
    const EmisPage = lazy(() => import('../modules/apps/emi-management/UsersPage'))
    const WalletTransactionsPage = lazy(() => import('../modules/apps/wallet-transactions/WalletTransactionsPage'))
    const CombinedUsersPage = lazy(() => import('../modules/apps/combined-user-management/UsersPage'))
    const ReportsPage = lazy(() => import('../modules/apps/reports/ReportsPage'))
    const ExpensesPage = lazy(() => import('../modules/apps/expense-management/ExpensesPage'))
    const ChargesPage = lazy(() => import('../modules/apps/charges-management/ChargesPage'))
    const BranchPage = lazy(() => import('../modules/apps/branch-management/UsersPage'))
    const FinanceAppFormPrint = lazy(() => import('../modules/apps/finance-print-application/FinancePrintApplicationPage'))
    return (
        <Routes>
            <Route element={<MasterLayout/>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard'/>}/>
                {/* Pages */}
                <Route path='dashboard' element={<DashboardWrapper/>}/>
                <Route path='builder' element={<BuilderPageWrapper/>}/>
                <Route path='menu-test' element={<MenuTestPage/>}/>
                {/* Lazy Modules */}
                <Route
                    path='crafted/pages/profile/*'
                    element={
                        <SuspensedView>
                            <ProfilePage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/pages/wizards/*'
                    element={
                        <SuspensedView>
                            <WizardsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/widgets/*'
                    element={
                        <SuspensedView>
                            <WidgetsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='crafted/account/*'
                    element={
                        <SuspensedView>
                            <AccountPage/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='finance-print-application/*'
                    element={
                        <SuspensedView>
                            <FinanceAppFormPrint/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='wallet-transactions/*'
                    element={
                        <SuspensedView>
                            <WalletTransactionsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='apps/chat/*'
                    element={
                        <SuspensedView>
                            <ChatPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='user-management/*'
                    element={
                        <SuspensedView>
                            <UsersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='view-all-users/*'
                    element={
                        <SuspensedView>
                            <CombinedUsersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='financer-management/*'
                    element={
                        <SuspensedView>
                            <FinancersPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='employee-management/*'
                    element={
                        <SuspensedView>
                            <EmployeesPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='distributor-management/*'
                    element={
                        <SuspensedView>
                            <DistributorsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='product-management/*'
                    element={
                        <SuspensedView>
                            <ProductsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='finance-management/*'
                    element={
                        <SuspensedView>
                            <FinancesPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='emi-management/*'
                    element={
                        <SuspensedView>
                            <EmisPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='branch-management/*'
                    element={
                        <SuspensedView>
                            <BranchPage/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='reports/*'
                    element={
                        <SuspensedView>
                            <ReportsPage/>
                        </SuspensedView>
                    }
                />
                <Route
                    path='expense-management/*'
                    element={
                        <SuspensedView>
                            <ExpensesPage/>
                        </SuspensedView>
                    }
                />

                <Route
                    path='charges-management/*'
                    element={
                        <SuspensedView>
                            <ChargesPage/>
                        </SuspensedView>
                    }
                />


                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404'/>}/>
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--kt-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress/>}>{children}</Suspense>
}

export {PrivateRoutes}
